<template>
  <div class="w-100">
    <div class="title-actions">
      <h5 class="title">{{$t('tutorialCreate')}}</h5>
      <div class="btns">
        <b-form-input v-model="filter" :placeholder="$t('search')" autofocus />
        <b-button variant="info" @click="create">
          <i class="fa fa-plus"></i>
        </b-button>
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr>
    <b-table striped bordered hover :items="tutorials" :fields="columns" :filter="filter">
      <div slot="created_at" slot-scope="tutorial">
        {{tutorial.value}}
      </div>
      <template slot="actions" slot-scope="tutorial">
        <div class="w-100 text-center">
          <b-button class="mx-1" variant="info" @click="edit(tutorial.item)"><i class="fa fa-pencil"></i></b-button>
          <b-button class="mx-1" variant="danger" @click="openModalDestroy(tutorial.item)"><i class="fa fa-trash"></i></b-button>
        </div>
      </template>
    </b-table>
    <b-modal ref="modalAddEdit" :title="title" no-close-on-backdrop>
      <b-form @submit.prevent="save">
        <div class="form-group">
          <label for="language">{{$t('language')}}</label>
          <b-form-select v-model="tutorial.lang" v-validate="'required'" name="language" id="language" :state="validateState('language')" :options="langs" />
          <span class="error-inputs">{{ errors.first('language') }}</span>
        </div>
        <div class="form-group">
          <label for="order">{{$t('order')}}</label>
          <b-form-input v-model="tutorial.order" v-validate="'required|numeric|min_value:1'" name="order" id="order" :state="validateState('order')" type="number" />
          <span class="error-inputs">{{ errors.first('order') }}</span>
        </div>
        <div class="form-group">
          <label for="description">{{$t('description')}}</label>
          <b-form-input v-model="tutorial.name" v-validate="'required|max:150'" name="description" id="description" :state="validateState('description')" />
          <span class="error-inputs">{{ errors.first('description') }}</span>
        </div>
        <div class="form-group">
          <label for="videoCode">{{$t('videoCode')}}</label>
          <b-form-textarea v-model="tutorial.video" v-validate="'required'" name="videoCode" id="videoCode" :state="validateState('videoCode')" />
          <span class="error-inputs">{{ errors.first('videoCode') }}</span>
        </div>
      </b-form>
      <div slot="modal-footer">
        <b-button @click="$refs.modalAddEdit.hide()">{{$t('cancel')}}</b-button>
        <b-button variant="primary" @click="save" class="ml-3">{{$t('save')}}</b-button>
      </div>
    </b-modal>
    <b-modal ref="modalDelete" :title="$t('confirmTitleDelete')" headerBgVariant="danger" headerTextVariant="light">
      <p>{{$t('confirmTextDelete')}}</p>
      <strong>{{tutorial.name}}</strong>
      <div slot="modal-footer">
        <b-button @click="$refs.modalDelete.hide()">{{$t('cancel')}}</b-button>
        <b-button variant="danger" @click="destroy" class="ml-3">{{$t('delete')}}</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      filter: '',
      title: '',
      langs: [],
      columns: [
        { key: 'name', label: this.$t('name'), sortable: true },
        { key: 'created_at', label: this.$t('createdAgo') },
        { key: 'actions', label: '' }
      ],
      console: this.$route.params.console
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'tutorials',
      'tutorial'
    ])
  },
  mounted() {
    document.title = `${this.$t('tutorialCreate')} - ITFA`
    this.langs = [
      { value: 'es', text: this.$t('langEs') },
      { value: 'en', text: this.$t('langEn') },
      { value: 'pt', text: this.$t('langPt') }
    ]
    if (this.tutorials.length < 1) {
      this.fetchData()
    }
  },
  methods: {
    create () {
      this.title = this.$t('create')
      this.$store.dispatch('SET_TUTORIAL', {})
      this.$refs.modalAddEdit.show()
    },
    edit (tutorial) {
      this.title = this.$t('edit')
      this.$store.dispatch('SET_TUTORIAL', tutorial)
      this.$refs.modalAddEdit.show()
    },
    openModalDestroy (tutorial) {
      this.$store.dispatch('SET_TUTORIAL', tutorial)
      this.$refs.modalDelete.show()
    },
    destroy () {
      const path = `auth/${this.lang}/${this.console}/super/tutorial/delete/${this.tutorial.id}`
      this.$axios.delete(path).then(() => {
        this.$store.dispatch('DELETE_TUTORIAL', this.tutorial.id)
        this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
        this.$refs.modalDelete.hide()
      })
    },
    fetchData () {
      const path = `auth/${this.lang}/${this.console}/super/tutorial/all`
      this.$axios.get(path).then((response) => {
        this.$store.dispatch('SET_TUTORIALS', response.data.data)
      })
    },
    save () {
      this.$validator.validate().then(result => {
        if (result) {
          if (!this.tutorial.id) {
            const path = `auth/${this.lang}/${this.console}/super/tutorial/store`
            this.$axios.post(path, this.tutorial).then((response) => {
              this.$store.dispatch('ADD_TUTORIAL', response.data.data)
              this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
              this.$store.dispatch('SET_TUTORIAL', {})
              this.$refs.modalAddEdit.hide()
            })
          } else {
            const path = `auth/${this.lang}/${this.console}/super/tutorial/update/${this.tutorial.id}`
            this.$axios.put(path, this.tutorial).then((response) => {
              this.$store.dispatch('UPDATE_TUTORIAL', response.data.data)
              this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
              this.$store.dispatch('SET_TUTORIAL', {})
              this.$refs.modalAddEdit.hide()
            })
          }
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    }
  }
}
</script>
